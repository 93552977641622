nav {
    height: 75px;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 9999;
    background-color: #282a36;
    border-bottom: 1px solid #323442;
}

nav h2{
    font-family: Degular;
    font-weight: 600 !important;
    display: inline-block;
    margin: 0;
}
nav .navLink{
    background: #5a6084;
    border-radius: 5px;
}

nav .navLink:hover{
    background: #5a6084;
    border-color: #8394ca;
}