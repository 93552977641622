.blogCard {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    background-color: var(--theme-ui-colors-surface,#323442);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 1px;
}

.blogCard:hover{
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: #ff79c6;
}

.blogCard img {
    border-radius: 5px 0 0 5px !important;
}
