.markdown-container{
  margin-bottom: 50px !important;
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3, 
.markdown-container h4,
.markdown-container h5,
.markdown-container h6{
  margin-top: 25px;
}

.markdown-container img{
    max-width: 100%;
}

.markdown-container h2{
  margin: 30px 0;
}

.markdown-container h6 {
  color:#ff79c6 !important;
  font-family: JetBrainsMono;
}


.markdown-container pre {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.markdown-container a {
  color: #858eff !important
}