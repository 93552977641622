@font-face {
  font-family: "Degular";
  src: url("../fonts/DegularVariable.woff2");
 }

@font-face {
  font-family: "JetBrainsMono";
  src: url("../fonts/JetBrainsMono.ttf");
}

@font-face {
  font-family: "Changa";
  src: url("../fonts/Changa.ttf");
  font-weight: 400;
}

*:hover{
  transition: 0.3s !important;
}
 
body {
  background-color: #282a36 !important;
  color: #FFFFFF !important;
  margin: 0;
  font-family: Changa, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1em;
}

a {
  color: #FFFFFF !important;
  text-decoration: none;
}

p {
  text-align: justify;
}

h1::before, h2::before, h3::before {
  content: '→';
  color: #50fa7b;
  margin-right: 5px;
}

h1::after, h2::after, h3::after {
  content: '()';
  color: #8be9fd;
  margin-left: 5px;
}

h3, h4, h5{
  color: #ff79c6;
  font-size: 1.1em !important;
  text-transform: capitalize;
}

h4{
  color: #8be9fd;
}

h5 {
  color: #50fa7b;
}

hr{
  box-sizing: border-box;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  border: none;
}

.btn {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: inline-block;
  vertical-align: baseline;
  font-weight: 500;
  white-space: nowrap;
  background-color: transparent;
  border-width: 1px;
  background-color: transparent;
  border-radius: 0px;
}

.btn:hover {
  color: #ffffff !important;
  border-color: #ffffff !important;
  border-width: 1px;
}

.text-secondary{
  color:#8394ca !important;
}

.messageBox {
  background: #323442;
  box-sizing: border-box;
  margin: 0px;
  padding: 16px;
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: #8be9fd;
  border-radius: 0px;
}

code{
  background-color: #323442;
  color: #8be9fd;
  font-family: JetBrainsMono;
}

blockquote {
  margin: 10px;
  border-radius: 0;
  border-left-color: #8394ca;
  border-left-style: solid;
  border-left-width: 4px;
  margin-top: 0;
  margin-left: 8px;
  margin-bottom: 16px;
  margin-right: 0;
}

blockquote p {
  padding: 16px;
  margin: 0;
}